import { navigate, Link } from "@reach/router"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { AuthContext } from "../context/auth"
import firebase from "gatsby-plugin-firebase"
import { Container, Navbar,  Nav } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"


const Header = ({ siteTitle }) => {
  const { user } = useContext(AuthContext)

  const handleLogout = async () => {
    await firebase.auth().signOut()
    navigate("/")
  }

  return (
    <header style={{backgroundColor: "#94b247"}}>
      <Container fluid>
        
          
        <Navbar  expand="md"  variant="dark">
        
          <Navbar.Brand href="/">
            <StaticImage 
            src="../images/transp-logo.png" 
            alt="Logo residencias transparentes"
            width="30"
            height="30"
            className="d-inline-block align-top" />
            {siteTitle}</Navbar.Brand>
          
          <Navbar.Toggle aria-controls="navbarResponsive" />
          <Navbar.Collapse id="nabarResposive">
          

            <Nav as="ul" className="col justify-content-end">
              <Nav.Item as="li">
                <Link to="/app/registro" className="nav-link" activeClassName="active">Registro</Link>
              </Nav.Item>
              <Nav.Item as="li">
                {!user ?(
                  <Link to="/app/acceso"className="nav-link" activeClassName="active">Acceso</Link>  
                ):(
                  <Link to="#!" className="nav-link" activeClassName="active" onClick={handleLogout}>Salir</Link> 
                )
                } 
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        
      </Container>
    </header> 
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
