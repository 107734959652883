import React from 'react'
import "./footer.css"
import { Container, Row, Col } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'

export const Footer = () => {
    return (
        <footer>
        <Container>
          <Row className="row">
          
          </Row>
          <Row>
            <Col lg={12}>
            
            <p className="text-white mt-5">Residencias transparentes es una colaboración de: </p>
            </Col>
            
            <Col lg={6} className="infor">
            <div>
              <StaticImage 
              src="../../images/logoir.png"
              width={280}
               /> 
              </div>
            <a href="https://www.inforesidencias.com"><small>Inforesidencias.com</small></a>
            </Col>

            <Col lg={6} >
              <div>
              <StaticImage 
              src="../../images/logoafi.png"
              width={150}
               /> 
              </div>
              
            <a href="https://www.afi.es/"><small>Analistas financieros internacionales</small></a>
            </Col>
            <p className="md-1 text-white">© {new Date().getFullYear()}</p>
          </Row>
        </Container>
        
        </footer>
    )
}
